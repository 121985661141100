



























































































import { Component, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FileResource from '@/models/graphql/FileResource';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUser from '@/models/graphql/CommunityUser';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import { deepGet } from '@/utils/ObjectHelpers';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';
import { mixins } from 'vue-class-component';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');
@Component({
  components: {
    PillWidget, FontAwesomeComponent, AvatarSoloWidget, ButtonIconComponent,
  },
  inheritAttrs: false,
})
export default class SpeakerCardWidget extends mixins(BreakpointWrapper, BaseCardWidget) {
  @Prop({ required: true, default: '' })
  readonly uid!: string;

  @Prop({ required: true, default: -1 })
  readonly id!: number;

  @Prop({ required: true, default: '' })
  readonly name!: string;

  @Prop({ required: true, default: '' })
  readonly firstName!: string;

  @Prop({ required: true, default: '' })
  readonly lastName!: string;

  @Prop({ required: false, default: '' })
  readonly prefix!: string;

  @Prop({ required: false, default: '' })
  readonly suffix!: string;

  @Prop({ required: false, default: null })
  readonly jobTitle!: string;

  @Prop({ required: false, default: false })
  readonly featured!: boolean;

  @Prop({ required: false, default: null })
  readonly employerName!: string;

  @Prop({ required: false, default: null })
  readonly photoFileResource!: FileResource | null;

  @Prop({ required: false, default: null })
  readonly _isBookmarked!: string | null;

  @Prop({ default: false })
  readonly _isRecommendedForMe!: boolean;

  @Prop({ default: null })
  readonly cardRoute!: string;

  @Prop({ default: 'DependencyWidgetStore' })
  readonly context!: string;

  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @Getter
  community!: Community;

  @Getter
  private authUser!: CommunityUser;

  // eslint-disable-next-line no-underscore-dangle
  private bookmarked = this._isBookmarked;

  private show = false;

  private FileResourceHelper = FileResourceHelper;

  private empty = this.photoFileResource === null;

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private get fullName(): string {
    let fullName = '';
    if (this.prefix) {
      fullName = `${this.prefix}`;
    }
    if (this.firstName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.firstName}`;
    }
    if (this.lastName) {
      const separator = fullName ? ' ' : '';
      fullName = `${fullName}${separator}${this.lastName}`;
    }
    if (this.suffix) {
      const separator = fullName ? ', ' : '';
      fullName = `${fullName}${separator}${this.suffix}`;
    }
    return fullName.trim();
  }

  private toggleBookmark(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.bookmarked) {
      const temp = this.bookmarked;
      this.bookmarked = null;
      this.unBookmark({ uid: temp })
        .catch(() => {
          this.bookmarked = temp;
        });
    } else {
      this.bookmarked = 'bookmarked';
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.SPEAKER,
      }).then((response) => {
        this.bookmarked = response?.uid || '';
        if (this.bookmarked) {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.SPEAKER,
            StatLoggerActions.ADD,
            '',
            this.id,
            this.uid,
            StatLoggerCategories.BOOKMARK,
            this.$i18n.locale,
          );
        }
      }).catch(() => {
        this.bookmarked = null;
      });
    }
  }
}
